import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/index/index')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('@/views/detail/detail')
  },
  {
    path: '/ralColor',
    name: 'ralColor',
    component: () => import('@/views/ral-color/index')
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import('@/views/contact-us/index')
  },
  {
    path: '/middleware',
    name: 'middleware',
    component: () => import('@/views/detail/middleware')
  },

]

const router = new VueRouter({
  routes
})

export default router
