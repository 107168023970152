<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
};
</script>

<style src="@/assets/css/iconfont.css"></style>
<style lang="scss">
html,body {
  margin: 0;
  padding: 0;
}
@import "@/assets/css/common.scss";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  min-height: 100%;
  font-size: 16px;
}
div,span {
  box-sizing: border-box;
}
</style>
